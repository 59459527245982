<template>
  <v-btn block @click="print()">Print DIP </v-btn>
</template>

<script>
export default {
  data() {
    return {
      output: null,
    };
  },
  methods: {
    print() {
      // Pass the element id here
      this.$htmlToPaper("printMe");
    },
  },
};
</script>
