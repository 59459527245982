<template>
  <v-container>
    <div v-for="action in actions" v-bind:key="action.Id">
      <v-row>
        <v-col>
          <h3>Action: {{ action.ChallengeOpportunity.Name }}</h3>
        </v-col>
        <!-- <v-col>
          <h3>Priority: {{ action.ChallengeOpportunity.Priority }}</h3>
        </v-col> -->
      </v-row>
      <v-row>
        <v-col>
          <p>Statement: {{ action.Statement }}</p>
        </v-col>
        <v-col>
          <p>Method: {{ action.Method }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p>Status: {{ action.Status }}</p>
        </v-col>
        <v-col>
          <p>Action being taken: {{ action.Action }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <p>Person reponsible: {{ action.ActionedBy.Name }}</p>
        </v-col>
        <v-col>
          <p>How will we do it: {{ action.Method }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-divider />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      actions: [],
    };
  },
  watch: {},
  mounted() {
    this.actions = this.$store.state.plan.DigitalPlan.ActionPlan;
  },
  created() {
    this.actions = this.$store.state.plan.DigitalPlan.ActionPlan;
  },
  methods: {},
};
</script>
