<template>
  <v-container>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Project Aim</th>
                <th class="text-left">Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in projectAims" :key="item.Aim">
                <td>{{ item.Aim }}</td>
                <td>{{ item.Type }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({
    ClientAimsHeaders: [
      { text: "Aim", value: "Aim", sortable: true },
      { text: "", value: "Edit", sortable: false },
    ],
  }),
  mounted() {},

  computed: {
    projectAims() {
      let aimsTemp = [];
      this.$store.state.plan.DigitalPlan.ClientAims.forEach((element) => {
        aimsTemp.push({ Aim: element.Value, Type: "Client" });
      });
      this.$store.state.plan.DigitalPlan.TeamAims.forEach((element) => {
        aimsTemp.push({ Aim: element.Value, Type: "Team" });
      });
      return aimsTemp;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
