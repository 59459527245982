<template>
  <div class="home">
    <NavigationBar :JobCode="jobCode" />
    <v-container>
      <PlanHeader :title="'Report'" />
      <v-row>
        <v-col cols="2">
          <v-btn block v-if="expand == false" @click="expand = !expand"
            ><span> Expand All </span> <v-icon right>mdi-chevron-down </v-icon>
          </v-btn>
          <v-btn block v-if="expand == true" @click="expand = !expand">
            <span> Collapse All </span> <v-icon right>mdi-chevron-up </v-icon>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="lockDIP()">
            <div v-if="$store.state.plan.DigitalPlan.Lock == true">
              <span>Unlock DIP</span>
              <v-icon right>mdi-lock-open-variant </v-icon>
            </div>
            <div v-else>
              <span> Lock DIP </span> <v-icon right>mdi-lock </v-icon>
            </div>
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="downloadJSON()">Download JSON </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn block @click="print()">Print DIP </v-btn>
        </v-col>
        <v-col cols="1"> </v-col>
        <v-col cols="3"> </v-col>
      </v-row>

      <div v-if="showWarning">
        <v-row>
          <v-col cols="4">
            <v-alert dense border="left" type="warning">
              You are on the UAT / Test server!
            </v-alert>
          </v-col>
        </v-row>
      </div>

      <v-row>
        <v-col>
          <div id="printMe">
            <ReportExpansionPanel :expandAll="expand" />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <NavigationButton
            v-bind:routeName="'Actions'"
            v-bind:label="'Back'"
          />
        </v-col>
        <v-col>
          <div align="right">
            <NavigationButton v-bind:routeName="'Home'" v-bind:label="'Home'" />
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

import PrintButton from "@/components/PrintButton.vue";
import PlanHeader from "@/components/PlanHeader.vue";
import NavigationButton from "@/components/NavigationButton.vue";
import ReportExpansionPanel from "@/components/ReportExpansionPanel.vue";
import NavigationBar from "@/components/NavigationBar.vue";
export default {
  components: {
    PrintButton,
    PlanHeader,
    NavigationButton,
    ReportExpansionPanel,
    NavigationBar,
  },
  computed: {
    jobCode() {
      return this.$store.state.plan.JobCode;
    },
    lastModified() {
      return this.$store.state.lastModified;
    },
    showWarning() {
      if (
        this.$store.state.ApiUri !=
        "https://dip-prod-function-app.azurewebsites.net/api"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  data: () => ({
    expand: false,
  }),
  created() {
    // This if statement checks to see if a plan is loaded upon landing on this route
    // If a plan is not loaded, redirect to Home page. else do nothing.
    if (this.$store.state.plan.JobCode == null) {
      this.$router.push({ name: "Home" });
    }
  },
  methods: {
    async lockDIP() {
      if (this.$store.state.plan.DigitalPlan.Lock == null) {
        this.$store.state.plan.DigitalPlan.Lock = true;
      } else {
        this.$store.state.plan.DigitalPlan.Lock =
          !this.$store.state.plan.DigitalPlan.Lock;
      }
      this.$store.state.plan.DigitalPlan = await this.packetUpdateDIP(
        this.$store.state.plan,
        this.$store.state.ApiUri,
        "Lock"
      );
    },
    print() {
      function printdiv(printdivname) {
        var headstr = "<html><head><title>Booking Details</title></head><body>";
        var footstr = "</body>";
        var newstr = document.getElementById(printdivname).innerHTML;
        var oldstr = document.body.innerHTML;
        document.body.innerHTML = headstr + newstr + footstr;
        window.print();
        document.body.innerHTML = oldstr;
        return false;
      }

      this.expand = true;
      setTimeout(function () {
        printdiv("printMe");
      }, 500);
    },
    async downloadDIPJSON() {
      try {
        const response = await this.$dip.get(
          `${this.$store.state.ApiUri}/GetResultFile?ProjectCode=${this.$store.state.plan.ProjectCode}`,
          { responseType: "blob" }
        );

        const fileURL = window.URL.createObjectURL(new Blob([response.data]));
        const fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `${this.$store.state.plan.ProjectCode}_DIP.json`
        );
        document.body.appendChild(fileLink);

        fileLink.click();
      } catch (error) {
        console.log(error.response);
        // Handle error as needed
      }
    },
  },
};
</script>
