<template>
  <v-container>
    <v-row>
      <v-col>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Data type</th>
                <th class="text-left">Location</th>
                <th class="text-left">Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in dataRequirements" :key="item.Id">
                <td>{{ item.Type }}</td>
                <td>{{ item.Location }}</td>
                <td>{{ item.Notes }}</td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "App",
  props: {},
  components: {},

  data: () => ({}),
  mounted() {},

  computed: {
    dataRequirements() {
      return this.$store.state.plan.DigitalPlan.DataRequirements;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
