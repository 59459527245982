<template>
  <v-row justify="center">
    <v-expansion-panels accordion v-model="panels" multiple>
      <v-expansion-panel v-for="(item, i) in headers" :key="i">
        <v-expansion-panel-header>
          {{ i + 1 }}. {{ item.name }}</v-expansion-panel-header
        >
        <v-expansion-panel-content v-if="item.name == 'Project details'">
          <ProjectDetailsDisplay />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Workshop attendees'">
          <AttendeesDisplay />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Project aims'">
          <ProjectAims />
        </v-expansion-panel-content>
        <v-expansion-panel-content
          v-if="item.name == 'Pain points / key challenges'"
        >
          <PainPointsDisplay />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Workshop outcomes'">
          <WorkshopOutcomes />
        </v-expansion-panel-content>

        <v-expansion-panel-content v-if="item.name == 'Contract'">
          <ContractClauseDisplay />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Data Outcomes'">
          <DataDisplay />
        </v-expansion-panel-content>
        <v-expansion-panel-content v-if="item.name == 'Project specifics'">
          <ProjectSpecificsDisplay />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-row>
</template>

<script>
import ProjectDetailsDisplay from "@/components/ProjectDetailsDisplay.vue";
import AttendeesDisplay from "@/components/AttendeesDisplay.vue";
import ProjectAims from "@/components/ProjectAims.vue";
import WorkshopOutcomes from "@/components/WorkshopOutcomes.vue";
import PainPointsDisplay from "@/components/PainPointsDisplay.vue";
import DataDisplay from "@/components/DataDisplay.vue";
import ContractClauseDisplay from "@/components/ContractClauseDisplay.vue";
import ProjectSpecificsDisplay from "@/components/ProjectSpecificsDisplay.vue";

export default {
  props: ["expandAll"],
  components: {
    ProjectDetailsDisplay,
    AttendeesDisplay,
    ProjectAims,
    WorkshopOutcomes,
    PainPointsDisplay,
    DataDisplay,
    ContractClauseDisplay,
    ProjectSpecificsDisplay,
  },
  data: () => ({
    panels: [],
    items: 8,
    headers: [
      { name: "Project details" },
      { name: "Workshop attendees" },
      { name: "Project aims" },
      { name: "Pain points / key challenges" },
      { name: "Workshop outcomes" },
      { name: "Contract" },
      { name: "Data Outcomes" },
      { name: "Project specifics" },
    ],
  }),
  watch: {
    expandAll: {
      handler() {
        if (this.expandAll == true) {
          this.all();
        } else {
          this.none();
        }
      },
      immediate: true,
    },
  },
  created() {},
  methods: {
    // Create an array the length of our items
    // with all values as true
    all() {
      this.panels = [...Array(this.items).keys()].map((k, i) => i);
    },
    // Reset the panel
    none() {
      this.panels = [];
    },
  },
};
</script>

<style src="../styles/expansionpanels.scss" lang="scss"></style>
